@use '@/styles/variables' as *;

.hoverIntent {
  // The `contents` display mode "Makes the container disappear,
  // making the child elements children of the element the next
  // level up in the DOM." Perfect for wrapper components.
  display: contents;
}

.demoContainer {
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.wall {
  background-color: $color-primary;
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 55%;

  z-index: 2;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  > div {
    color: $color-white;
    font-size: 24px;
    text-align: center;
    padding: 15px;
  }
}

.cat {
  width: 550px;
  height: 600px;
  transition: transform 150ms;

  z-index: 1;

  &.active {
    transform: translateY(-100px);
  }
}
